body {
    padding-top: 58px;
    background-color: #f7f7f7;
}

.navbarMobileIconsList {
    margin-top: -5px;

    li {
        margin-right: 15px;
    }
}

.nav-item {
    cursor: pointer;
}

.sideMenu {
    height: 110vh;

    .card2 {
        /*height: 30%;*/
        overflow-x: hidden;
        overflow-y: auto;

        .listScroll {
            height: 35%;
            overflow-x: hidden;
            overflow-y: auto;

            .list-group-item {
                border: none;
                padding: 5px;
            }
        }
    }
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.no-outline:focus, .no-outline:active:focus, .no-outline.active:focus,
.no-outline.focus, .no-outline:active.focus, .no-outline.active.focus {
    outline: none !important;
    box-shadow: none !important;
}

.navLink {
    svg {
        fill: white;
        height: 16px;
        width: 16px;
    }
}

.navLink:hover {
    svg {
        opacity: 0.75;
    }
}


.navLinkActive {
    svg {
        opacity: 0.5;
    }
}

::-webkit-scrollbar-track {
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    border-radius: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 2px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
    background-color: #bdc3c7;
}

@-webkit-keyframes dyinglight {
    15% {
        transform: scale(1.6);
    }

    50% {
        transform: rotate(-89deg);
    }

    100% {
        transform: rotate(-90deg);
    }
}

@keyframes dyinglight {
    15% {
        transform: scale(1.6);
    }

    50% {
        transform: rotate(-89deg);
    }

    100% {
        transform: rotate(-90deg);
    }
}

.dl {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
}

.dl__square {
    display: block;
    width: 80px;
    height: 80px;
    animation-name: dl_animation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes dl_animation {
    0% {
        width: 80px;
        height: 80px;
    }

    50% {
        width: 100px;
        height: 100px;
    }

    100% {
        width: 80px;
        height: 80px;
    }
}


.imgdescription {
    margin-left: -10px;
    position: absolute;
    top: 40%;
    bottom: 50%;
    background: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility opacity 0.2s;
}

.imgwrap:hover img {
    transition: all .5s ease-in-out;
    transform: scale(.95);
    opacity: .5;
}

.imgwrap:hover .imagePlusIcon {
    transition: all .5s ease-in-out;
    transform: scale(1.15);
}

.imgwrap:hover .imgdescription {
    visibility: visible;
    opacity: 1;
    transition: all .5s ease-in-out;
    font-size: 1.2em;
    color: #000;
    font-family: interstate-light,Helvetica, highway_gothic_wideregular, Arial, sans-serif;
}

.groupListIcon {
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all .25s ease-in;

    a:link {
        text-decoration: none;
    }


    a:visited {
        text-decoration: none;
    }


    a:hover {
        text-decoration: none;
    }


    a:active {
        text-decoration: none;
    }
}

.groupListIcon:hover {
    background-color: #ecf0f1;
}

.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.tableLastRight {
    td:last-child {
        text-align: right;
    }

    th:last-child {
        text-align: right;
    }
}

.hiddenRow {
    padding: 0 !important;
}

.tableRowIcon {
    transition: all 0.5s;

    :hover {
        opacity: 0.5;
    }
}

.tableRowIconRotated {
    transform: rotate(180deg);
}

.no-suggestions {
    color: #999;
    padding: 0.5rem;
}

.suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 200px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(300px + 1rem);
}

.suggestions li {
    padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
    background-color: #2980b9;
    color: white;
    cursor: pointer;
    font-weight: 700;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
}

.btn-warning:hover {
    color: white;
}

.resourceDialogCheckBox {
    margin-right: 20px;
    margin-top: 5px;
}

.dropDownPost {
    svg {
        margin-right: 5px
    }

    font-size: 14px !important;
}

.dropDownPostBIG {
    svg {
        margin-right: 5px
    }

    font-size: 14px !important;
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
}

.btnDRPW {
    :hover {
        color: white !important;
    }
}

.customSondaj {
    .cs_row {
        label {
            margin-right: 5px;
        }

        button {
            padding: 4px;
            padding-left: 10px;
            padding-right: 10px;
            outline: none;
            border: 0;
            border-radius: 0;
            margin-bottom: 5px;
            margin-right: 5px;
        }

        .btn-link {
            color: black !important;
            text-decoration: none !important;
        }

        .btn-secondary {
            background-color: #ecf0f1;
            color: initial;
        }
    }
}

.emojiSelector {
    margin-top: 5px;
    text-align: center;
    margin: auto;
    width: 150px;
}

.comment {
    margin-top: 5px;
    margin-bottom: 5px;

    img {
        height: 45px;
    }

    .data {
        margin-left: 50px;
        font-size: 14px;
    }

    textarea {
        font-size: 14px;
    }

    .atchBTN {
        svg {
            path {
                stroke: white !important;
            }
        }
    }
}

.atchBTN {
    svg {
        path {
            stroke: white !important;
        }
    }
}

.badgeBox {
    img {
        width: 100%;
        border-radius: 30px;
    }

    .overlayText {
    }
}

.nopad {
    p {
        margin-bottom: 5px;
        font-size: 13px;

        svg {
            margin-right: 5px;
        }
    }
}

.initialA {
    color: initial !important;

    a {
        color: initial !important;
    }

    li {
        a {
            color: initial !important;
        }
    }

    p {
        color: initial !important;
    }

    strong {
        color: initial !important;
    }

    img {
        border-radius: 50%;
    }

    .react-icon {
        margin-right: 10px;
    }
}

.nodeco {
    color: black !important;
    text-decoration: none !important;
}

.nodeco:hover {
    text-decoration: none !important;
}

.btn-warning {
    color: white;
}

.btn-warning:hover {
    color: white;
}

.initialN {
    color: white !important;
    text-align: right
}

.navbarCollapse123 {
    a {
        color: white !important;
    }
}

.asdfasd {
    color: white !important;
}

.dot_green {
    height: 15px;
    width: 15px;
    background-color: #2ecc71;
    border-radius: 50%;
    display: inline-block;
}

.dot_red {
    height: 15px;
    width: 15px;
    background-color: #e74c3c;
    border-radius: 50%;
    display: inline-block;
}

input[type="file"] {
    display: block !important;
    width: 100% !important;
    height: 100% !important;
    opacity: 0 !important;
    overflow: hidden !important;
    z-index: 100 !important;
    margin-top: -20px;
    padding-top: -20px;
    top: -20px;
}

.chat_conversation_list_group_item {
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 5px;
}

.messagesBox {
    height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
}

@import 'themes.scss';

@mixin themable($theme-name, $theme-map) {
    .#{$theme-name} {

        .primary-fill {
            fill: map-get($theme-map, primaryColor) !important;
        }

        .primary-color {
            color: map-get($theme-map, primaryColor) !important;
        }

        .btn-warning {
            background-color: map-get($theme-map, warningColor) !important;
            border-color: map-get($theme-map, warningColor) !important;
            color: white;
        }

        .input-group-append {
            background: transparent !important;
        }

        .navbar {
            height: 58px !important;
            background-color: map-get($theme-map, navbarBackgroundColor) !important;

            .bg-dark {
                background-color: map-get($theme-map, navbarBackgroundColor) !important;
            }
        }

        .navbarAvatar {
            height: 30px;
            margin-top: -15px;

            img {
                height: 80px;
                position: relative;
                z-index: 999999999;
                border: 3px solid map-get($theme-map, searchColor);
                padding: 0;
                border-radius: 50%;
            }
        }

        .centerImg {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 150px;
            border: 5px solid map-get($theme-map, searchColor);
            border-radius: 50%;
        }

        .themed_notif_badge {
            background-color: map-get($theme-map, searchColor);
        }

        .btnDRP {
            .btn {
                background: transparent !important;
            }
        }

        .input-group-append {
            .btn-primary {
                background-color: map-get($theme-map, primaryColor) !important;
            }
        }

        .btn-primary {
            background-color: map-get($theme-map, primaryColor) !important;
        }

        .initialA:hover {
            color: map-get($theme-map, linkColor) !important;

            li {
                a {
                    color: map-get($theme-map, linkColor) !important;
                }
            }

            p {
                color: map-get($theme-map, linkColor) !important;
            }

            strong {
                color: map-get($theme-map, linkColor) !important;
            }
        }

        .imagePlusIcon {
            position: absolute;
            bottom: 0;
            left: 65%;
            fill: map-get($theme-map, linkColor);
        }

        .btn-link {
            color: map-get($theme-map, linkColor) !important;
        }

        .btnDRP {
            :hover {
                color: map-get($theme-map, linkColor) !important;
            }
        }

        .btnDRP:hover {
            color: map-get($theme-map, linkColor) !important;
        }

        a {
            text-decoration: none !important;
            color: map-get($theme-map, linkColor) !important;
        }

        a:link:not(.initialN) {
            text-decoration: underline;
            color: map-get($theme-map, linkColor) !important;
        }

        a:hover {
            text-decoration: none;
            color: map-get($theme-map, linkColor) !important;
        }

        .navBarAvatarDropdownMenu {
            margin-top: 6px;
            padding-top: 20px;

            a {
                color: black !important;
            }

            a:active {
                background-color: white;
            }

            a:hover {
                color: map-get($theme-map, linkColor) !important;
            }
        }

        .asds {
            color: map-get($theme-map, linkColor) !important;
        }

        .asds a {
            color: map-get($theme-map, linkColor) !important;
        }

        .archiveHierarchyViewLIST {
            .treeBlock {
                color: map-get($theme-map, linkColor) !important;
                padding: 5px;
                padding-right: 0;

                li {
                    a {
                        color: map-get($theme-map, linkColor) !important;
                    }
                }

                p {
                    color: map-get($theme-map, linkColor) !important;
                }

                strong {
                    color: map-get($theme-map, linkColor) !important;
                }
            }
        }

        .archiveView {
            .fileIcon {
                color: #2c3e50;
                cursor: pointer;
                padding: 10px;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                label {
                    cursor: pointer;
                }

                .svgICON {
                    color: #2c3e50;
                    margin-left: 5px;
                    margin-top: 10px;
                    margin-bottom: 5px;
                    margin-right: 15px;
                    transform: scale(2);
                }
            }

            .fileIcon:hover {
                background-color: #f8f9fa;
                color: map-get($theme-map, linkColor) !important;
            }
        }

        .btn-secondary {
            background-color: map-get($theme-map, secondaryColor) !important;
            border-color: map-get($theme-map, secondaryColor) !important;
        }

        .btn-search {
            border: none !important;
            background-color: map-get($theme-map, searchColor) !important;
        }

        .groupHeader {
            background-color: map-get($theme-map, secondaryColor);
            border-radius: 5px;
            color: white !important;
            padding: 10px;
            padding-left: 20px;
            padding-right: 15px;

            a:link {
                text-decoration: none;
            }


            a:visited {
                text-decoration: none;
            }


            a:hover {
                text-decoration: none;
            }


            a:active {
                text-decoration: none;
            }

            h4 {
                margin-top: 5px;
            }

            .btn {
                margin-top: -40px;
            }
        }

        .btn-warning {
            background-color: map-get($theme-map, warningColor) !important;
            border-color: map-get($theme-map, warningColor) !important;
            color: white;
        }

        .link-ct {
            color: map-get($theme-map, sideMenuSubtitleColor) !important;
        }

        .post {
            margin-bottom: 20px;

            .card-header {
                background-color: white;

                img {
                    height: 45px;
                }

                .headerData {
                    padding-left: 50px;
                    margin-top: -3px;

                    .uName {
                        font-size: 14px;
                        margin-bottom: 0px;
                        display: block;
                    }

                    .cAt {
                        margin-bottom: 0px;
                        margin-top: -50px !important;
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }

            .card-text {
                /*overflow: hidden;        
                text-overflow: ellipsis;
                line-height: 1.5em;
                height: 7.5em;*/
            }

            .featured {
                background-color: map-get($theme-map, featuredPost);
                color: white
            }
        }

        .msg_bubble_container {
            width: 100%;

            .msg_bubble {
                width: 60%;
                padding: 5px;
                padding-left: 10px;
                padding-right: 10px;
                background-color: map-get($theme-map, secondaryColor);
                border-radius: 5px;
                color: white;
                white-space: pre-line;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .platform {
                margin: auto;
                display: block;
            }

            .mine {
                float: right;
                color: black;
                background-color: rgba(0,0,0,.03) !important;
            }
        }

        .initialN:hover {
            color: map-get($theme-map, secondaryColor) !important;
        }

        .selectedA {
            color: map-get($theme-map, secondaryColor) !important;
        }

        .nobkg {
            background-color: transparent !important;
            outline: none !important;
            border: none !important;
        }

        .transparentBkg {
            background-color: transparent !important;
            outline: none !important;
            border: none !important;
        }

        .modal-footer {
            .btn-primary {
                background-color: map-get($theme-map, primaryColor) !important;
            }
        }
    }
}

@include themable(default, $default);
@include themable(mymarionnaud, $mymarionnaud);
@include themable(sameday, $sameday);
@include themable(aerostar-test, $aerostar);

.truenav{
    .nav-link{
        fill: white!important;
        color: white!important;
    }
}

.initialN {
    color: white !important;
    text-align: right
}

.ReactModal__Content{
    margin-top: 50px!important;
}

@media only screen and (max-width: 576px) {
    .navbarCollapse {
        z-index: 999999;
        margin-top: -10px;
        margin-right: -15px;
        margin-left: -15px;
        padding: 15px;

        .nav-item {
            color: white !important;
        }
    }

    .nbmbl {
        margin-top: -10px;
    }
}
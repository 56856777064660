﻿$default:(
    navbarBackgroundColor: #732E81,
    primaryColor: #3D9294,
    searchColor: #3D9294,
    sideMenuSubtitleColor: #732E81,
    linkColor: #732E81,
    secondaryColor: #C58ED0,
    warningColor: #953BA7,
    featuredPost: #f39c12
);

$aerostar:(
    navbarBackgroundColor: #1C318E,
    primaryColor: #A1C7FA,
    searchColor: #A1C7FA,
    sideMenuSubtitleColor: #1C318E,
    linkColor: #1C318E,
    secondaryColor: #3F5BA7,
    warningColor: #e67e22,
    featuredPost: #27ae60
);

$mymarionnaud:(
    navbarBackgroundColor: #732E81,
    primaryColor: #3D9294,
    searchColor: #3D9294,
    sideMenuSubtitleColor: #732E81,
    linkColor: #732E81,
    secondaryColor: #C58ED0,
    warningColor: #953BA7,
    featuredPost: #f39c12
);

$sameday:(
    navbarBackgroundColor: #732E81,
    primaryColor: #3D9294,
    searchColor: #3D9294,
    sideMenuSubtitleColor: #732E81,
    linkColor: #732E81,
    secondaryColor: #C58ED0,
    warningColor: #953BA7,
    featuredPost: #f39c12
);